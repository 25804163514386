<template>
  <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides">
    <n-message-provider :placement="messagePlacement">
      <Loading />
      <ToastInjector />
      <div class="d-flex sr__signup-wrap justify-content-center">
        <div class="sr__signup" :class="{ 'dwolla-registration': dwollaLayoutData }">
          <img src="/images/Logo-AgentSnap.png" alt="" />
          <slot />
        </div>
      </div>
    </n-message-provider>
  </n-config-provider>
</template>

<script lang="ts" setup>
import { darkTheme } from 'naive-ui';
import { getGreenButtonThemeOverrides } from '../../base/utils/theme-overrides';
const { $toastError } = useNuxtApp();
const { dwollaLayoutData } = useDwollaLayout();

const themeOverrides = getGreenButtonThemeOverrides();

onMounted(() => {
  try {
    localStorage;
  } catch {
    $toastError('Unable to access local storage. Please check your browser settings and try again.');
  }

  if (!isCookieSupported()) {
    $toastError('Please enable cookies in your browser settings and try again.');
  }
});
</script>

<style scoped lang="scss">
.sr__signup {
  @include login-sign-card;
  .about-snaprefund {
    img {
      width: 20px;
    }
    span {
      font-size: 11px;
    }
  }
}
.dwolla-registration {
  border: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 575px) {
  .sr__signup {
    border: 0;
    min-width: auto;
    width: 100%;
    margin-top: 15px;
    padding: 20px;
  }
  .customer-type {
    width: 100%;
    height: 154px;
  }
}
</style>
