// navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
// configurations. For example, when blocking cookies via the Advanced
// Privacy Settings in IE9, it always returns true. And there have been
// issues in the past with site-specific exceptions.
// Don't rely on it.

// try..catch because some in situations `document.cookie` is exposed but throws a
// SecurityError if you try to access it; e.g. documents created from data URIs
// or in sandboxed iframes (depending on flags/context)

export const isCookieSupported = (): boolean => {
  try {
    // Create cookie
    document.cookie = 'cookietest=1';
    const result = document.cookie.indexOf('cookietest=') != -1;
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return result;
  } catch (e) {
    return false;
  }
};
